<template>
  <v-dialog
    class="lighten-5 guest-forms"
    id="container"
    v-model="registrationModal"
    max-width="1000"
    scrollable
    @keydown.esc="signInAccount"
    persistent
  >
    <v-card class="px-8" flat>
      <v-btn class="modal-close-btn icon-with-hover" icon @click="signInAccount"><v-icon>fa-times</v-icon></v-btn>
      <v-form ref="registrationForm" v-model="isFormValid">
        <div align="center" class="form-container ma-0">
          <v-row class="my-0">
            <v-col align-self="center" class="pb-0">
              <h2 class="form-title mt-2">{{ $t('Registration') }}</h2>
              <v-col class="form-wrapper registration-form pa-0 mt-5">
                <p class="mb-2 text-left">
                  <span>{{ $t('To register, please fill out all the required fields.') }}</span><br>
                </p>
                <p class="mb-6 text-left">
                  <span>{{ $t('After you have confirmed your registration via the received email, you can log in and sign up for the upcoming auction') }}</span>
                </p>
                <v-row>
                  <v-col cols="12" md="6" xs="12" align="center" class="pr-md-9 pt-0 pb-0">
                    <v-text-field
                      type="text"
                      v-model="email"
                      :label="$t('Email')"
                      :rules="[validationRequiredRule(), validationEmailRule()]"
                      class="input-field input-background-transparent customizable"
                      :class="{'error-border': !email && showMessage}"
                      single-line
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-text-field>
                    <!--                    <v-text-field-->
                    <!--                      type="text"-->
                    <!--                      v-model="emailConfirm"-->
                    <!--                      :label="$t('Confirm Email')"-->
                    <!--                      :rules="[validationRequired(), validationEmail()]"-->
                    <!--                      class="input-field"-->
                    <!--                      v-bind:class="{'error-border': !emailConfirm && showMessage}"-->
                    <!--                    />-->
                    <div class="input-field password-strength-block">
                      <v-text-field
                        v-model="password"
                        :label="$t('Password')"
                        :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                        @click:append="showPassword = !showPassword"
                        :type="showPassword ? 'text' : 'password'"
                        class="input-field input-field-small-append-icon input-background-transparent customizable"
                        :rules="[validationRequiredRule(), validationPasswordRule()]"
                        @focus="$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1"
                        ref="passwordField"
                        :class="{'error-border': !password && showMessage}"
                        single-line
                      >
                        <template #message="{ message }">
                          {{ $t(message) }}
                        </template>
                      </v-text-field>
                      <!--<v-progress-linear  class="strength-bar" :color="score.color" :value="score.value"></v-progress-linear>-->
                    </div>
                    <v-text-field
                      v-model="confirmPassword"
                      :label="$t('Confirm Password')"
                      type="password" class="input-field input-background-transparent customizable"
                      :rules="[validationRequiredRule(), validationPasswordConfirmationRule(password, confirmPassword)]"
                      :class="{'error-border': !confirmPassword && showMessage}"
                      single-line
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-text-field>
                    <div v-if="!isMobile">
                      <div class="info-text">
                        <v-icon>fa-circle-info</v-icon>
                        <p>
                          <span v-if="appFeatures.liveAuctionFunctionality">
                            <span v-if="appSettings.commission && appSettings.commission.live !== 0">{{ $t('registration_live_bidding_commission', {tLiveBiddingRate: appSettings.commission.live}) }}</span>
                            <span v-else>{{ $t('registration_live_bidding_commission_free') }}</span>
                            <br><br>
                          </span>
                          <span v-if="appSettings.commission && appSettings.commission.absentee !== 0">{{ $t('registration_commission_bidding_commission', {tCommissionBiddingRate: appSettings.commission.absentee}) }}</span>
                          <span v-else>{{ $t('registration_commission_bidding_commission_free') }}</span>
                        </p>
                      </div>
                      <v-checkbox
                        v-model="agreeTerm"
                        :rules="[validationRequiredRule()]"
                        class="checkbox-wrapper mt-0"
                      >
                        <template #label>
                          <div @click.stop="" class="text">
                            <span>{{ $t('By using this service, I agree to the') }}</span>
                            <a :href="appSettings.url_rules" target="_blank" class="link-text terms-link customizable">{{ $t('Terms and Conditions') }}</a>
                          </div>
                        </template>
                        <template #message="{ message }">
                          {{ $t(message) }}
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" xs="12" align="center" class="pl-md-9 pt-0 pb-0">
                    <v-row>
                      <v-col class="col-6 pb-0">
                        <v-text-field
                          type="text"
                          v-model="first_name"
                          :label="$t('First name')"
                          :rules="[validationRequiredRule()]"
                          class="input-field input-background-transparent customizable"
                          :class="{'error-border': !first_name && showMessage}"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="col-6 pb-0">
                        <v-text-field
                          type="text"
                          v-model="last_name"
                          :label="$t('Last name')"
                          class="input-field input-background-transparent customizable"
                          :rules="[validationRequiredRule()]"
                          :class="{'error-border': !last_name && showMessage}"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="col-6 pt-0 pb-0">
                        <v-text-field
                          type="text"
                          v-model="company_name"
                          :label="$t('Company name')"
                          class="input-field input-background-transparent"
                          single-line >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="col-6 pt-0 pb-0">
                        <v-text-field
                          type="text"
                          v-model="tax_id"
                          :label="$t('Tax ID')"
                          class="input-field input-background-transparent"
                          single-line >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <div class="v-input input-field v-input--hide-details v-text-field v-text-field--is-booted v-text-field--placeholder no-padding-top">
                      <div class="v-input__control">
                        <v-select
                          class="select-input input-background-transparent customizable"
                          v-model="country"
                          :items="countries"
                          append-icon="fa-angle-down"
                          :label="$t('Country')"
                          :class="{'error-border': !country && showMessage}"
                          item-text="[1]"
                          item-value="[0]"
                          :rules="[validationRequiredRule()]"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <v-row class="city-row" style="margin-top: -4px;">
                      <v-col class="py-0 col-8">
                        <v-text-field
                          type="text"
                          v-model="street"
                          :rules="[validationRequiredRule()]"
                          :label="$t('Street')"
                          class="input-field input-background-transparent customizable"
                          :class="{'error-border': !street && showMessage}"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="py-0 col-4">
                        <v-text-field
                          type="text"
                          v-model="house_number"
                          :rules="[validationRequiredRule()]"
                          :label="$t('Number')"
                          class="input-field input-background-transparent customizable"
                          :class="{'error-border': !house_number && showMessage}"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="py-0 col-4">
                        <v-text-field
                          type="text"
                          v-model="zipcode"
                          :label="$t('Zip Code')"
                          :rules="[validationRequiredRule()]"
                          class="input-field input-background-transparent customizable"
                          :class="{'error-border': !zipcode && showMessage}"
                          :maxlength="10"
                          @keydown="inputZipHandler"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="py-0 col-8">
                        <v-text-field
                          type="text"
                          v-model="city"
                          :label="$t('City')"
                          class="input-field input-background-transparent customizable"
                          :rules="[validationRequiredRule()]"
                          :class="{'error-border': !city && showMessage}"
                          single-line
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-text-field
                      type="tel"
                      @keydown="inputPhoneHandler"
                      v-model="telephone1"
                      :label="$t('Telephone')"
                      class="input-field input-background-transparent customizable"
                      :rules="[validationRequiredRule()]"
                      :class="{'error-border': !telephone1 && showMessage}"
                      single-line
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-text-field>
                    <div v-if="!isMobile">
                      <v-checkbox
                        v-model="allow_advertisements"
                        :hide-details="true"
                        class="checkbox-wrapper mt-0"
                      >
                        <template #label>
                          <div class="text">
                            <span>{{ $t('Allow advertisements from the auction house (optional)') }}</span>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-checkbox
                        v-model="enable_wishlist_notifications"
                        :hide-details="true"
                        class="checkbox-wrapper mt-0"
                      >
                        <template #label>
                          <div class="text">
                            <span>{{ $t('Get E-Mail notifications for articles on the wishlist (optional)') }}</span>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>

                <div v-if="isMobile" class="mb-4">
                  <div class="info-text">
                    <v-icon>fa-circle-info</v-icon>
                    <p>
                      <span v-if="appFeatures.liveAuctionFunctionality">
                        <span v-if="appSettings.commission?.live !== 0">{{ $t('registration_live_bidding_commission', {tLiveBiddingRate: appSettings.commission.live}) }}</span>
                        <span v-else>{{ $t('registration_live_bidding_commission_free') }}</span>
                        <br><br>
                      </span>
                      <span v-if="appSettings.commission?.absentee !== 0">{{ $t('registration_commission_bidding_commission', {tCommissionBiddingRate: appSettings.commission.absentee}) }}</span>
                      <span v-else>{{ $t('registration_commission_bidding_commission_free') }}</span>
                    </p>
                  </div>
                  <v-checkbox
                    v-model="agreeTerm"
                    :rules="[validationRequiredRule()]"
                    class="checkbox-wrapper mt-0"
                  >
                    <template #label>
                      <div @click.stop="" class="text">
                        <span>{{ $t('By using this service, I agree to the') }}</span>
                        <a :href="appSettings.url_rules" target="_blank" class="link-text terms-link customizable">{{ $t('Terms and Conditions') }}</a>
                      </div>
                    </template>
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-model="allow_advertisements"
                    :hide-details="true"
                    class="checkbox-wrapper mt-0"
                  >
                    <template #label>
                      <div class="text">
                        <span>{{ $t('Allow advertisements from the auction house (optional)') }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    v-model="enable_wishlist_notifications"
                    :hide-details="true"
                    class="checkbox-wrapper mt-0"
                  >
                    <template #label>
                      <div class="text">
                        <span>{{ $t('Get E-Mail notifications for articles on the wishlist (optional)') }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
                <v-row class="mb-0">
                  <v-col sm="12" align="center">
                    <div class="my-2 pt-2 pb-0">
                      <v-btn
                        :loading="loading"
                        @click="signUp"
                        class="btn primary-btn register-btn customizable"
                      >
                        {{ $t('Register') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import Countries from 'i18n-iso-countries'
import { localFeatures, timeouts } from '@/utils/constants';
import apiRequests from '@/api/apiRequests';
import frontendValidations from '@/utils/rules';

import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import inputHandlers from '@/utils/inputHandlers';
import useRootStore from '@/stores/rootStore';

Countries.registerLocale(en)
Countries.registerLocale(de)

export default {
  async beforeMount() {
    const entries = Object.entries(Countries.getNames(this.$i18n.locale, { select: 'official' }));
    this.countries = entries
  },
  mounted() {
    // validate all fields when opening the modal
    // this.$refs.registrationForm.validate();
  },
  data: () => ({
    registrationModal: false,
    showPassword: false,
    email: null,
    password: '',
    confirmPassword: null,
    showMessage: false,
    errorField: null,
    message: '',
    agreeTerm: false,
    allow_advertisements: false,
    enable_wishlist_notifications: false,
    toolTipPosition: 'right',
    countries: [],
    country: null,
    language: null,
    first_name: null,
    last_name: null,
    street: null,
    house_number: null,
    zipcode: null,
    city: null,
    telephone1: null,
    company_name: null,
    tax_id: null,
    emailError: false,
    loading: false,
    features: localFeatures,
    isFormValid: false
  }),
  computed: {
    ...mapState(useRootStore, ['appSettings', 'appStoreLang', 'isMobile', 'appFeatures']),
  },
  /* watch: {
    // validate all input if the language is changed
    appStoreLang() {
      this.$nextTick(() => {
         this.$refs.registrationForm.validate();
      });
    }
  }, */
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT']),
    validationRequiredRule: frontendValidations.required,
    validationEmailRule: frontendValidations.email,
    validationPasswordRule: frontendValidations.password,
    validationPasswordConfirmationRule: frontendValidations.passwordConfirmation,
    isShowErrorTooltip(fieldName) {
      if (!this.showMessage || !this.errorField) {
        return false;
      }
      return fieldName === this.errorField;
    },
    signInAccount() {
      this.$refs.registrationForm.reset()
      if (this.$route.name === 'registration') {
        this.$router.push({ name: 'login' })
      }
      this.closeModal()
    },
    async signUp() {
      this.$refs.registrationForm.validate()

      if (this.isFormValid) {
        const formData = {
          language: this.$i18n.locale,
          first_name: this.first_name,
          last_name: this.last_name,
          company_name: this.company_name,
          tax_id: this.tax_id,
          country: this.country,
          city: this.city,
          street: this.street,
          house_number: this.house_number,
          zipcode: this.zipcode,
          telephone1: this.telephone1,
          email: this.email,
          password: this.password,
          allow_advertisements: !!this.allow_advertisements,
          enable_wishlist_notifications: !!this.enable_wishlist_notifications
        }

        // Show loading
        this.UPDATE_GLOBAL_STATE({ key: 'loading', value: true });

        // Call sign up account
        try {
          this.loading = true
          await apiRequests.signUp(formData);

          const text = this.$t('Thank You! A confirmation e-mail has been sent to you.') + '<br />' + this.$t('Please check your inbox for an e-mail.')
          this.$swal.fire({
            position: 'center',
            icon: 'success',
            title: this.$t('Almost done'),
            html: text,
            showConfirmButton: false,
            timer: timeouts.closeSweetalert
          })
          this.signInAccount()
        } catch (e) {
          if (e.data?.error) {
            this.SET_TEMP_ALERT({ flavor: 'error', content: e.data.data[0].errorShortText })
          }
        } finally {
          this.UPDATE_GLOBAL_STATE({ key: 'loading', value: false });
          this.loading = false
        }
      }
    },
    showTooltip(message, errorField = 'email') {
      // Get width
      if (window.innerWidth < 968) {
        this.toolTipPosition = 'bottom';
      } else {
        this.toolTipPosition = 'right';
      }

      this.message = message;
      this.showMessage = true;
      this.errorField = errorField;

      // Hide after 5s
      setTimeout(() => {
        this.showMessage = false;
        this.errorField = null;
      }, 5000);
    },
    inputZipHandler: inputHandlers.inputZipHandler,
    inputPhoneHandler: inputHandlers.inputPhoneHandler,
    openModal() {
      this.registrationModal = true
    },
    closeModal() {
      this.registrationModal = false
    }
  },
};
</script>

<style>
  .confirmation-page {
    width:800px;
  }
  .user-profile-container .v-input__control .v-select__slot .v-label{
    left: 0 !important;
    top: 5px !important;
  }
  .password-strength-block {
    position: relative;
  }
  .password-strength-block .input-field .v-input__slot{
    /* margin-bottom: 15px; */
    margin-bottom: 10px;
  }
  .password-strength-block .strength-bar {
    position: absolute;
    width:100%;
    top: 48px;
    display: none;
  }
  .terms-link {
    padding-left: 3px;
  }
</style>
<style lang="scss" scoped>
.info-text {
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 100%;
  margin: 0 auto 15px;
  background: #87A4CF;
  padding: 11px 24px 11px 19px;
  color: #264D86;
  border-radius: 8px;
  .v-icon {
    font-size: 27px;
    color: #264D86;
  }
  p {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    margin: 0 0 0 15px;
  }
}
.guest-forms {
  .form-wrapper {
    background: #fff;
    width: 984px;
  }
}
.registration-form {
  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }
}
</style>
