<template>
  <div>
    <h2 class="pa-0">{{ $t('Images') }}</h2>
    <div class="files-list mt-0" v-if="files.length">
      <v-data-table
        :headers="headers"
        :items="files"
        :items-per-page="itemPerPage"
        :no-data-text="$t('No files exist')"
        sort-by="index"
        sort-asc
        :footer-props="{
          'items-per-page-all-text': $t('All'),
          'items-per-page-text': $t('Rows per page'),
          'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
          'items-per-page-options':[3, 15, 30, 50, 100, -1]
        }"
      >
        <template #[`item.articleNumber`]="{ item }">
          <span @click="openFile(item)">{{ item.articleNumber }}</span>
        </template>
        <template #[`item.image`]="{ item }">
          <span @click="openFile(item)">
            <v-img :src="getImageView(item.articleNumber)" width="50" height="50" />
          </span>
        </template>
        <template #[`item.size`]="{ item }">
          <span @click="openFile(item)">{{ Math.round(item.size / 1000) }}kb</span>
        </template>
        <template #[`item.birthtime`]="{ item }">
          <span>{{ getFormattedDate(item.birthtime, appStoreLang, false, false) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip top allow-overflow>
            <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click.stop="downloadFile(item)" class="icon-with-hover ">fa-download</v-icon>
            </template>
            <span>{{ $t('Download') }}</span>
          </v-tooltip>
          <v-tooltip top allow-overflow>
            <template #activator="{ on, attrs }">
              <al-input-file-btn
                v-bind="attrs" v-on="on"
                @fileChange="handleFileChange"
                @click="setIndex(item)"
                acceptFile=".jpg"
                icon="fa-upload"
                btnClass="no-background-hover icon-with-hover"
              />
            </template>
            <span>{{ $t('Replace') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="info-text mt-6" v-else style="max-width: 400px;">
      <v-icon>fa-circle-info</v-icon>
      <p>
        {{ $t('There are no uploaded files') }}
      </p>
    </div>
    <v-row>
      <v-col>
        <al-input-file-btn
          @fileChange="handleFileUpload"
          acceptFile=".jpg"
          :multiple="true"
          :btnText="$t('Add new images')"
          btnClass="btn mr-3 v-size--large mt-0"
          :loading="loading"
        />
        <v-btn @click="deleteFiles" class="btn mt-0" large :disabled="removeLoading || files.length === 0 || loading">
          <span>{{ $t('Delete all images') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <confirmation-modal
      :title="$t('Do you really want to delete all images?')"
      @submit="submitRemove"
      @cancel="cancelRemove"
      :loading="removeLoading"
      ref="removeConfirmationModal"
    />
    <confirmation-modal
      :title="$t('Do you really want to replace this image?')"
      @submit="submitReplace"
      @cancel="cancelReplace"
      :loading="replaceLoading"
      ref="replaceConfirmationModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import apiRequests from '@/api/apiRequests'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import AlInputFileBtn from '@/components/form/input-file'
import * as crypto from 'crypto';
import _ from 'lodash'
import useRootStore from '@/stores/rootStore';
import { getFormattedDate } from '@/services/i18n';

export default {
  name: 'ArticleImageManagement',
  components: {
    ConfirmationModal,
    AlInputFileBtn
  },
  props: {
    auctionId: {
      type: Number
    },
    articleId: {
      type: Number
    }
  },
  data() {
    return {
      files: [],
      headers: [
        {
          text: this.$t('Filename'),
          value: 'articleNumber',
          align: 'left',
        },
        {
          text: this.$t('Image'),
          value: 'image',
          align: 'left',
        },
        {
          text: this.$t('Size'),
          value: 'size',
          align: 'left',
        },
        {
          text: this.$t('Created at'),
          value: 'birthtime',
          align: 'left',
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          align: 'left',
          sortable: false
        },
      ],
      replaceFile: null,
      start: 0,
      end: 0,
      page: 0,
      itemPerPage: 3,
      replaceLoading: false,
      loading: false,
      removeLoading: false,
      imageIndex: -1
    }
  },
  async created() {
    await this.getImages();
  },
  computed: {
    ...mapState(useRootStore, ['appStoreLang']),
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    getImageView(articleNumber) {
      let index = this.files.findIndex((el) => el.articleNumber === articleNumber);
      return this.files[index].image + '?v' + Math.random() * 10000;
    },
    getFormattedDate,
    deleteFiles() {
      this.$refs.removeConfirmationModal.openModal()
    },
    handleFileChange(file) {
      this.replaceFile = file
      this.$refs.replaceConfirmationModal.openModal()
    },
    setIndex(item) {
      this.imageIndex = this.files.findIndex((el) => el.articleNumber === item.articleNumber);
    },
    async submitRemove() {
      try {
        this.removeLoading = true;
        await apiRequests.removeArticleFiles(this.auctionId, this.articleId)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Images deleted successfully') })
        this.files = [];
        this.removeLoading = false;
        this.cancelRemove()
      } catch (e) {
        this.removeLoading = false;
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error deleting the images') })
        this.cancelRemove()
      }
    },
    cancelRemove() {
      this.$refs.removeConfirmationModal.closeModal()
    },
    cancelReplace() {
      this.replaceFile = null
      this.$refs.replaceConfirmationModal.closeModal()
    },
    async openFile(file) {
      try {
        window.open(file.image)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error opening the file') })
      }
    },
    async downloadFile(item) {
      try {
        let index = this.files.findIndex((el) => el.articleNumber === item.articleNumber);
        const { data } = await apiRequests.getSingleImage(this.auctionId, this.articleId, index)
        const a = document.createElement('a')
        a.href = URL.createObjectURL(data);
        a.setAttribute('download', item.articleNumber)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error downloading the image') })
      }
    },
    async submitReplace() {
      this.replaceLoading = true
      const image = await new Promise((resolve, reject) => {
        this.fileConvertion(this.replaceFile, resolve, reject);
      });
      try {
        let data = {
          payload: image.payload,
          auctionId: this.auctionId,
          articleId: this.articleId,
          imageIndex: this.imageIndex,
          checksum: image.checksum
        }
        const result = await apiRequests.uploadArticleFiles(data)
        this.files[this.imageIndex] = result.data.data.files[0];
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Image {tFilename} replaced successfully', {tFilename: result.data.data.files[0].articleNumber}) })
      } catch (e) {
        if (e.data?.error && e.data.data[0].errorShortText === 'user.file.unsuccessful.file.too.large') {
          this.SET_TEMP_ALERT({ flavor: 'error', timeout: 5000, content: this.$t('There was an error replacing the image, the image is too large') })
        } else {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error replacing the image') })
        }
      } finally {
        this.replaceLoading = false
        this.cancelReplace()
      }
    },
    async handleFileUpload(files) {
      let error = false;
      this.loading = true;
      let images = await this.convertToBase64(files);
      images.sort((a, b) => {return a.name.localeCompare(b.name);});
      let currentName = null;
      for (let i = 0; i < images.length; i++) {
        try {
          const currentImage = images[i];
          currentName = currentImage.name;
          const data = {
            payload: currentImage.payload,
            auctionId: this.auctionId,
            articleId: this.articleId,
            checksum: currentImage.checksum
          }
          let result = await apiRequests.uploadArticleFiles(data);
          this.files = this.files.concat(result.data.data.files);
        } catch (e) {
          if (e.data?.error && e.data.data[0].errorShortText === 'user.file.unsuccessful.file.too.large') {
            this.SET_TEMP_ALERT({ flavor: 'error', timeout: 5000, content: this.$t('There was an error uploading the image {tFilename}, the image is too large', {tFilename: currentName}) })
          }
          error = true;
          continue;
        }
      }
      if (!error) {
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t(`Images uploaded successfully`) })
      } else {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t(`There was an error uploading some images`) })
      }
      this.loading = false;
    },
    async convertToBase64(files) {
      return new Promise((resolve, reject) => {
        const toBase64Tasks = _.map(files, (file) => {
          return new Promise((resolve2, reject2) => {
            this.fileConvertion(file, resolve2, reject2);
          });
        });
        Promise.all(toBase64Tasks).then((result) => {
          resolve(result);
        })
      });
    },

    fileConvertion(file, resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let payload = reader.result.split('base64,')[1];
        resolve({ payload, name: file.name, checksum: crypto.createHash('sha256').update(payload).digest('hex') });
      }
      reader.onerror = error => reject(error);
    },

    async getImages() {
      this.files = [];
      try {
        const { data } = await apiRequests.getArticleFiles(this.auctionId, this.articleId)
        if (!data.error) {
          this.files = data.data.files
        }
      } catch (err) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the images') })
      }
    }
  }
}
</script>

<style lang="scss">
.progress-text {
  display: block;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  color: #262626;
}
.no-background-hover
{
  box-shadow: none;
  background-color: transparent !important;
  &::before {
    background-color: transparent !important;
  }
}
</style>
