<template>
  <v-card class="auth-container-wrapper">
    <a v-if="(!isMobile && bannerUrl) || (isMobile && bannerUrl && mobileViewShowBanner)" :href="appSettings.url_auction_house" target="_blank">
      <v-img :src="bannerUrl" class="banner-img" contain />
    </a>
    <v-app-bar style="z-index: 2" class="main-header" elevation="0" color="white" absolute :key="appStoreLang">
      <a v-if="(!isMobile && showLogoOnDesktop) || (isMobile && showLogoOnMobile)" :href="appSettings.url_auction_house" target="_blank" class="logo">
        <v-img v-if="appSettings.url_logo_auction_house" :src="appSettings.url_logo_auction_house" contain width="100px" height="100px" />
        <v-img v-else :src="require('@/assets/logo.svg')" contain width="100px" height="100px" />
      </a>
      <div class="desktop-menu" v-if="!isTablet">
        <v-btn class="navigation-text" @click="openDashboard" :class="{ active: $route.name === 'dashboard' }" :disabled="!cookiesStatus">
          <span class="navigation-text__icon">
            <v-icon>fa-home</v-icon>
          </span>
          <span>{{ $t('menu_home') }}</span>
        </v-btn>
        <v-btn class="navigation-text" @click="openProductCatalogue" :class="{ active: $route.name === 'catalogue' }" :disabled="!cookiesStatus">
          <span class="navigation-text__icon">
            <v-icon>fa-book-open</v-icon>
          </span>
          <span>{{ $t('menu_catalogue') }}</span>
        </v-btn>
        <v-btn v-if="appFeatures.guestAuctionFunctionality" class="navigation-text" @click="openGuestAuction" :class="{ active: $route.name === 'guestLiveAuction' }" :disabled="!cookiesStatus">
          <span class="navigation-text__icon">
            <v-icon>fa-hammer</v-icon>
          </span>
          <span>{{ $t('menu_guest_auction') }}</span>
        </v-btn>
        <v-menu offset-y open-on-hover content-class="sub-menu" transition="slide-y-transition" v-if="!_.isEmpty(pastAuctions)">
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="navigation-text" :class="{ active: $route.name === 'postAuctions' }" :disabled="!cookiesStatus">
              <span class="navigation-text__icon">
                <v-icon>fa-book</v-icon>
              </span>
              <span>{{ $t('Past Auctions') }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="auction in pastAuctions" :key="auction.id">
              <router-link :to="{ name: 'pastAuctions', params: { id: auction.code } }" class="navigation-icon mx-0">
                <span>{{ $t('Auction') }} {{ auction.code + " (" + moment(String(auction.start_at)).format('D.M.Y') +")" }}</span>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer />
      <v-text-field
        v-if="searchFieldEnabled"
        v-model="searchTerm"
        width="48px"
        height="38px"
        class="mx-md-4 search search-field"
        solo
        hide-details="auto"
        append-icon="fa-search"
        :label="$t('Search')"
        :placeholder="$t('Search')"
        @click:append="searchData"
        @keyup.enter="searchData"
      />
      <theme-switch class="mr-2 mt-1" />
      <div class="user-lang-switcher" v-if="!isViewer">
        <lang-siwtcher :key="appStoreLang" :selected="lang === 'zh' ? 'en' : lang" z-index="1010" theme="light" />
      </div>
      <v-btn class="navigation-icon search-icon" width="48px" height="48px" icon @click="search" v-if="isTablet && searchFieldEnabled">
        <v-icon>fa-search</v-icon>
      </v-btn>
      <v-btn @click="openLogin" class="navigation-icon mr-0 mt-1" width="48px" height="48px" icon v-if="isTablet">
        <div class="v-icon">
          <v-icon>fa-sign-in-alt</v-icon>
        </div>
      </v-btn>
      <button type="button" class="burger" @click="mobileMenu = !mobileMenu" :class="{ active: mobileMenu }">
        <span />
      </button>
      <div class="menu" :class="{ active: mobileMenu }">
        <div class="menu__overlay" />
        <div class="menu__bg" />
        <div class="menu__navigation">
          <v-btn @click="openDashboard" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'dashboard' }" :disabled="!cookiesStatus">
            <div class="v-icon">
              <v-icon>fa-home</v-icon>
            </div>
            <span>{{ $t('menu_home') }}</span>
          </v-btn>
          <v-btn @click="openProductCatalogue" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'catalogue' }" :disabled="!cookiesStatus">
            <div class="v-icon">
              <v-icon>fa-book-open</v-icon>
            </div>
            <span>{{ $t('menu_catalogue') }}</span>
          </v-btn>
          <v-btn @click="openGuestAuction" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet && appFeatures.guestAuctionFunctionality" :class="{ active: $route.name === 'guestLiveAuction' }" :disabled="!cookiesStatus">
            <div class="v-icon">
              <v-icon>fa-hammer</v-icon>
            </div>
            <span>{{ $t('menu_guest_auction') }}</span>
          </v-btn>
          <v-list-group content-class="sub-menu" :disabled="!cookiesStatus" v-if="isTablet && !_.isEmpty(pastAuctions)">
            <template #activator>
              <v-btn
                class="navigation-icon" width="48px" height="48px" icon
                :class="{ active: $route.name === 'pastAuctions' }" :disabled="!cookiesStatus"
              >
                <div class="v-icon">
                  <v-icon>fa-book</v-icon>
                </div>
                <span>{{ $t('Past Auctions') }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="auction in pastAuctions" :key="auction.id">
                <v-btn class="navigation-icon" icon @click="$router.push({ name: 'pastAuctions', params: { id: auction.code } }); mobileMenu = false" :disabled="!cookiesStatus">
                  <div class="v-icon" style="width:0;" />
                  <span class="ml-0">{{ $t('Auction') }} {{ auction.code + " (" + moment(String(auction.start_at)).format('D.M.Y') +")" }}</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-list-group>
        </div>
      </div>
      <span v-if="!isTablet">{{ $t('Login for full functionality') }}</span>
      <v-btn @click="openLogin" class="navigation-icon logout-icon" icon v-if="!isTablet" :disabled="!cookiesStatus">
        <span class="v-icon">
          <v-icon>fa-sign-in-alt</v-icon>
        </span>
      </v-btn>
      <search-modal ref="searchModal" />
    </v-app-bar>
  </v-card>
</template>
<script>
import {mapActions, mapState} from 'pinia'
import ThemeSwitch from '@/components/header/theme-switch'
import SearchModal from '@/components/modals/search-modal'
import { AuctionFlavor, AuctionStatusType, localFeatures, Role } from '@/utils/constants';
import apiRequests from '@/api/apiRequests'
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import _ from 'lodash';

export default {
  name: 'GuestNav',
  props: {
    userId: Number,
  },
  components: {
    ThemeSwitch,
    SearchModal
  },
  data () {
    return {
      mobileMenu: false,
      dialog: false,
      loading: false,
      question: null,
      isTablet: window.innerWidth <= 1300,
      searchFieldEnabled: localFeatures.guestSearchField,
      searchTerm: '',
      lang: navigator.language.split('-')[0],
      pastAuctions: [],
      bannerUrl: localFeatures.urlBannerAuctionhouse,
      mobileViewShowBanner: localFeatures.mobileViewShowBanner,
      showLogoOnDesktop: localFeatures.showLogoOnDesktop,
      showLogoOnMobile: localFeatures.showLogoOnMobile
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appSettings', 'appStoreLang', 'appLoading', 'cookiesStatus', 'isMobile','appFeatures']),
    buttonStatus() {
      return !this.question || this.disableButton
    },
    isViewer() {
      return this.userProfile.role === Role.viewer
    }
  },
  async created() {
    console.log('detected browser language: ' + this.lang);
    await this.getPastAuctions();
    window.addEventListener('resize', this.resizeHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE']),
    resizeHandler() {
      this.isTablet = window.innerWidth <= 1300
    },
    search() {
      this.$refs.searchModal.openModal()
    },
    openDashboard() {
      if (this.$route.name === 'dashboard') {
        this.UPDATE_GLOBAL_STATE({ key: 'showAuctionOverview', value: true })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
      this.mobileMenu = false
    },
    openProductCatalogue() {
      if (this.$route.name !== 'catalogue') {
        this.$router.push({ name: 'catalogue' })
        this.mobileMenu = false
      }
    },
    openHome() {
      this.$router.push({ name: 'dashboard' })
      this.mobileMenu = false
    },
    openLogin() {
      this.$router.push({ name: 'login' })
      this.mobileMenu = false
    },
    openGuestAuction() {
      if (this.$route.name !== 'guestLiveAuction') {
        this.$router.push({ name: 'guestLiveAuction' })
        this.mobileMenu = false
      }
    },
    searchData() {
      let searchTerm = this.searchTerm.trim()
      //searchTerm = searchTerm.replace(" ", "%20")
      if (searchTerm !== '' && (!this.$route.query.q || this.$route.query.q !== searchTerm)) {
        this.$router.push({ name: 'search', query: { q: searchTerm } });
      }
      this.searchTerm = '';
    },
    async getPastAuctions() {
      try {
        if (_.isEmpty(this.userProfile)) {
          const res = await apiRequests.getGuestAuctions();
          const auctions = res.data
          this.pastAuctions = auctions.data.filter(el => {
            return moment(el.active_until).diff(this.moment(), 'seconds') > 0 && el.type === AuctionFlavor.live && el.status === AuctionStatusType.completed
          })
          this.pastAuctions.sort((a, b) => { return b.id - a.id });
        }
      } catch (e) {
        this.error = true;
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss">
.logo {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}
.logout-icon {
  @media (max-width: 968px) {
    position: relative;
  }
  span {
    text-transform: none;
  }
}
.menu__navigation .v-list-item {
  padding: 0px;

  &.v-list-item--link:before,
  &:hover {
    background: none !important;
  }

  .v-list-group__items {
    padding-left: 16px;
  }
  .v-list-item__icon.v-list-group__header__append-icon
  {
    margin-left: 16px;
  }
}
</style>
